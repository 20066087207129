/** @format */

import { ref, watch, computed } from '@vue/composition-api'

import store from '@/store'

export default function useInvoicesList(proxy) {
  const isLoading = ref(false)
  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '序号',
      thStyle: {
        width: '100px',
      },
    },
    {
      key: 'real_name',
      label: '姓名',
      sortable: false,
      thStyle: {
        width: '130px',
      },
    },
    {
      key: 'tp',
      label: 'Tp',
      sortable: false,
      thStyle: {
        width: '120px',
      },
    },
  ]
  const perPage = ref(100)
  const pageSize = ref(100)
  const rank = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref()
  const perPageOptions = [25, 50, 100]
  const score_time = [{ value: 'year', label: '年' }, { value: 'month', label: '月' }, { value: 'week', label: '周' }]
  const score_type = [{ value: '1', label: '未结算' }, { value: '2', label: '已结算' }]
  const type = ref(2)
  const score_cate = [{ value: '1', label: '个人' }, { value: '2', label: '层级' }, { value: '3', label: '团队' }]
  const cate = ref()
  const score_log_type = [{ value: '1', label: 'TP' }, { value: '2', label: '佣金' }]
  const log_type = ref()
  const date_type = ref()
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, rank, log_type, type, date_type, cate], () => {
    pageSize.value = perPage.value
    refetchData()
  })

  // 数据获取
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('commission-module/fetchInvoices', {
        pageSize: pageSize.value,
        page: currentPage.value,
        title: searchQuery.value,
        date_type: date_type.value,
        type: type.value,
        rank: rank.value,
        cate: cate.value,
        log_type: log_type.value
      })
      .then(response => {
        const { data, total } = response
        // console.log(response)
        callback(data)
        totalInvoices.value = total
      })
  }
  // 数据删除
  const confirmDelete = id => {
    store.dispatch('brand-module/deleteInvoices', id).then(response => {
      if (response.code == 0) {
        proxy.$bvToast.toast(response.msg, {
          title: response.msg,
          variant: 'success',
          solid: true,
        })
        refetchData()
      } else {
        proxy.$bvToast.toast(response.msg, {
          title: response.msg,
          variant: 'danger',
          solid: true,
        })
      }
    })
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    date_type,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    refInvoiceListTable,
    score_time,
    score_type,
    type,
    statusFilter,
    isLoading,
    refetchData,
    confirmDelete,
    score_cate,
    cate,
    score_log_type,
    log_type,
    rank
  }
}
