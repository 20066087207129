/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    brandDetail: {},
  },
  mutations: {
    setBrandDetail(state, payload) {
      // console.log(payload)
      state.brandDetail = payload
    },
  },
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/scoreCard/notTp', { params: { type: 2, ...queryParams } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
